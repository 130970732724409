@use '../_variable' as *;

// 汎用セレクトボックス
.select {
  @include tmp_form_select;

  margin: rem(48) 0;
}

// 汎用テキストボックス
.textbox {
  @include tmp_form_textbox;
}

// 見出し
.question {
  @include tmp_headline_sub;

  margin-bottom: rem(24);
}

// 補足情報
.note {
  margin: rem(14) 0 0;
  font-size: rem(14);
}

// 性別選択
.genderOptions {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: rem(30) 0;
  list-style: none;
}

.radio {
  display: none;
}

// 性別選択の丸ラベル
.genderLabel {
  @include rect(rem(84));

  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(12);
  font-weight: bold;
  color: $c_dark;
  background-color: $c_bg;
  border-radius: 50%;
  transition: color $t_ease, background-color $t_ease;

  .radio:checked ~ & {
    color: $c_lightness;
    background: $c_current;
  }
}

// 職業選択の一覧
.occupationOptions {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: rem(24);
  width: 100%;
  padding: 0 rem(18);
  margin: 0;
  list-style: none;
}

// 職業選択のラベル
.occupationLabel {
  @include tmp_form_radio($top: 0, $left: 0);

  position: relative;
  display: block;
  padding: 0 0 0 rem(36);
}
.description {
  display: block;
  color: $c_note;
}
