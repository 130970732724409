@use '../_variable' as *;

// 一覧
.list {
  padding: 0;
  margin: 0;
  list-style: none;
  border-top: 1px solid $c_border;
}

.item {
  position: relative;
  padding: 0;
  margin: 0;
  font-size: rem(14);
  list-style: none;
  border-bottom: 1px solid $c_border;
}

.checkbox {
  display: none;
}

.label {
  position: relative;
  display: flex;
  padding: rem(12) rem(12) rem(12) rem(36);

  // チェックボックス
  svg:first-child {
    @include rect(rem(18));

    position: absolute;
    top: 0;
    bottom: 0;
    left: rem(0);
    margin: auto rem(3);
  }

  .done {
    opacity: 0;
    stroke: $c_current;
    stroke-width: 4;
    transition: opacity $t_duration;
  }

  &::before {
    @include rect(rem(24));

    position: absolute;
    top: 0;
    bottom: 0;
    left: rem(0);
    display: block;
    margin: auto 0;
    content: '';
    border: rem(1.5) solid $c_ui_border;
    border-radius: rem(3);
  }

  .checkbox:checked ~ & {
    .done {
      opacity: 1;
      stroke-dasharray: 29.7833245;
      stroke-dashoffset: 29.7833245;
      transition: none;
      animation: checkPath $t_duration linear 0s forwards;
    }
  }
}
