@use '../_variable' as *;

// 単一選択問題
.singleChoice {
}
.question {
  @include tmp_headline_page;

  text-align: left;
  margin-top: rem(24);
}

// 一覧
.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  position: relative;
  padding: 0;
  margin: 0;
  font-size: rem(14);
  list-style: none;
}
