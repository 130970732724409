@use 'modern-css-reset';
@use '_variable' as *;

// GoogleFonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@400;700&display=swap');

html {
  min-height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', 'Noto Sans JP', sans-serif;
  font-size: vw(16); // 幅375pxのアートボードで16px相当
  line-height: 1.5;
  color: $c_dark;
  background-color: $c_bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // ダイアログの背後をスクロールさせない
  &.isFixedScroll {
    height: 100%;
    overflow: hidden;
  }
}

// Reactをレンダリングする要素
// 表示直後のチラツキを抑えるため、予め画面サイズにそろえる
#root {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh; // あとでJSが計測した正確なディスプレイの高さに上書きされる
  min-height: -webkit-fill-available; /* for iPhone Safari */
  transition: min-height $t_duration; /* for iPhone Safari */
}
