@use '../_variable' as *;
@use 'sass:math';

// 回答済
.article {
  padding: 0 rem(24);
  margin: rem(36) 0 0;
  text-align: center;
}

// 番組ロゴ
.logo {
  display: block;
  width: 50vmin;
  aspect-ratio: 16 / 9;
  margin: 0 auto;
}

.pageTitle {
  @include tmp_headline_page;

  margin-top: 0; // 余白を画像でコントロールできるようにするため、打ち消す。
}

.body {
  margin: rem(16) 0 0;
  font-size: rem(14);
}

.footer {
  margin: rem(24) 0 rem(48);
}

.button {
  @include tmp_button;

  margin: rem(16) 0 0;
}

// ポイントGET
.pointGet {
  $w: 14;
  $h: 21;

  position: relative;
  font-size: rem(18);
  font-weight: bold;
  margin: rem(18) 0;

  &::before,
  &::after {
    display: inline-block;
    width: rem($w);
    height: rem($h);
    vertical-align: top;
    content: '';
    border-top: 1px solid currentColor;
  }
  &::before {
    margin: 0 0.5em 0 0;
    transform: matrix(1, (1 * math.div($h, $w)), 0, 1, 0, ($h * 0.5));
  }
  &::after {
    margin: 0 0 0 0.5em;
    transform: matrix(1, (-1 * math.div($h, $w)), 0, 1, 0, ($h * 0.5));
  }
}

.myPoint {
  text-align: center;
  font-size: rem(16);
}

.cancel {
  display: block;
  width: 100%;
  margin: rem(24) 0 rem(48);
  font-size: rem(16);
  color: $c_link;
  text-align: center;
}

.secret {
  margin: 1em 0 0;
  font-size: rem(18);
  line-height: 2;
}
