@use '../_variable' as *;

.status {
  @include fit;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-weight: bold;
  color: $c_ui_border;

  .icon {
    @include rect(10vmin);

    &.isLoading {
      animation: upSideDown 2s ease infinite;
    }
  }
}

.path {
  fill: $c_ui_border;
}
