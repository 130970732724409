@use '../function/rem' as *;
@use '../mixin/fit' as *;
@use '../mixin/rect' as *;
@use '../color' as *;
@use '../transition' as *;

/** セレクトボックス
* font-size 18 + line-height 1.5
*/
@mixin tmp_form_select($font-size: rem(16), $width: 100%) {
  position: relative;
  display: flex;
  align-items: center;
  width: $width;
  height: rem(48);
  padding: rem(6) rem(30) rem(6) rem(12);
  overflow: hidden;
  font-size: $font-size;
  color: $c_dark;
  border: rem(1) solid $c_ui_border;
  border-radius: rem(4);

  select {
    @include fit;

    width: 100%;
    border-radius: rem(4);
    opacity: 0;
  }

  svg {
    @include rect(rem(16));

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: auto rem(6);
    path {
      fill: $c_dark;
    }
  }

  span {
    display: block;
    width: 100%;
    overflow: hidden;
    font-size: $font-size;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/** テキストボックス
* font-size 16
*/
@mixin tmp_form_textbox($font-size: rem(16), $width: 100%) {
  position: relative;
  display: flex;
  align-items: center;
  width: $width;
  padding: rem(8) rem(16);
  overflow: hidden;
  font-size: $font-size;
  color: $c_dark;
  border: rem(1) solid $c_ui_border;
  border-radius: rem(4);
  appearance: none;

  &::placeholder {
    font-size: rem(14);
    color: $c_note;
  }
}

/** テキストエリア
* font-size 16
*/
@mixin tmp_form_textarea($font-size: rem(16), $width: 100%) {
  position: relative;
  display: flex;
  align-items: center;
  width: $width;
  max-width: 100%;
  padding: rem(8) rem(16);
  overflow: hidden;
  font-size: $font-size;
  line-height: 1.5;
  color: $c_dark;
  border: rem(1) solid $c_ui_border;
  border-radius: rem(4);
  appearance: none;
}

/** ラジオボタン
* size: 24
*/
@mixin tmp_form_radio($size: 24, $top: null, $right: null, $bottom: null, $left: null) {
  &::before,
  &::after {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    display: block;
    content: '';
    border-radius: 50%;
  }
  &::before {
    @include rect(rem($size));

    background: $c_bg;
    border: 1px solid $c_ui_border;
  }
  &::after {
    @include rect(rem($size * 0.5));

    margin: rem($size * 0.25);
    background: $c_current;
    transition: transform $t_ease;
    transform: scale(0);
    transform-origin: center;

    // 選択中
    input[type='radio']:checked ~ & {
      transform: scale(1);
    }
  }

  // ページを表示したときの初期チェック
  &[data-checked='true']::after {
    transform: scale(1);
  }
}
