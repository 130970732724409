@keyframes checkPath {
  0%,
  50% {
    stroke-dashoffset: 29.7833385;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes upSideDown {
  0%,
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
