$c_darkness: #18202f;
$c_dark: #1e2635;
$c_lightness: #fff;
$c_light: #f7f8fa;

$c_current: #0050ff;
$c_accent: #0050ff;
$c_error: #eb4e3d;
$c_link: #5b82db;
$c_disabled: #d8e0e4;
$c_bg: #f7f8fa;
$c_note: #999;
$c_shade: #353a4066;
$c_border: #d4d4d4;
$c_ui_border: #e0e0e0;

$c_shadow: #0009;
$c_screen: #0009;
