@use '../_variable' as *;

.layout {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  background-color: $c_lightness;
  transition: background-color $t_ease;

  &[aria-busy='true'] {
    background-color: rgba($c_lightness, 0);
  }
}

.header {
  width: 100%;
  margin: 0;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  padding: rem(18) rem(18) rem(48);

  .hasGlobalNavigation & {
    margin-bottom: rem(60 + 12);
  }
}

.footer {
  margin: auto 0 0;
}

// default styles
.pageTitle {
  @include tmp_headline_page;
}
