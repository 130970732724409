@use '../_variable' as *;

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 rem(24);
  margin: rem(24) 0;
}
.pageTitle {
  @include tmp_headline_page;
}

.guide {
  margin: rem(24) 0 0;
  font-size: rem(14);
  text-align: center;
}

.footer {
  width: 100%;
  margin: 0 0 rem(24);
}

.submit {
  @include tmp_button;

  width: 100%;

  &:disabled {
    background-color: $c_disabled;
  }
}

.cancel {
  display: block;
  width: 100%;
  font-size: rem(16);
  color: $c_link;
  text-align: center;
}
