@use '../_variable' as *;

// ヘッダー
.information {
  font-size: rem(14);
}

.pageTitle {
  @include tmp_headline_page;

  margin-top: rem(24);
}

.paragraph {
  margin: rem(24) 0 0;
  font-size: rem(14);
}

// 実施期間
.dl {
  display: block;
  margin: rem(18) 0 0;
  font-size: rem(14);

  &:first-child {
    margin-top: 0;
  }
  dt {
    display: inline-block;

    &::after {
      margin: 0 0.5em;
      content: ':';
    }
  }
  dd {
    display: inline-block;
  }
}

// 質問
.article {
  margin: rem(48) 0 0;
}

.question {
  @include tmp_headline_sub;

  text-align: left;
}

.guide {
  margin: rem(14) 0 0;
  font-size: rem(14);

  svg {
    @include rect(rem(16));

    display: inline-block;
    vertical-align: middle;
  }
}

.subTitle {
  font-size: rem(14);
  font-weight: bold;
}

.article__body {
  margin: rem(18) 0 0;
}

// ラジオボタン
.radio {
  display: none;
}

.radioLabel {
  @include tmp_form_radio($top: rem(12), $left: 0);

  position: relative;
  display: flex;
  padding: rem(12) rem(12) rem(12) rem(36);

  // ラジオボタンの位置
  &::before,
  &::after {
    top: rem(12);
    left: 0;
  }
}

// テキストエリア
.textarea {
  @include tmp_form_textarea;

  height: 10em;
  margin: rem(16) 0 0;
  transition: background-color $t_duration, height $t_duration;

  &:disabled {
    height: 2.5em;
    background-color: $c_bg;

    &::placeholder {
      color: $c_note;
    }
  }
}
.textareaCounter {
  margin: rem(12) 0 0 0;
  font-size: rem(12);
  color: $c_note;
  text-align: right;
}

// 送信

.submit {
  @include tmp_button;

  margin: rem(36) 0;

  &:disabled {
    background-color: $c_disabled;
  }
}

.cancel {
  display: block;
  width: 100%;
  margin: rem(36) 0;
  font-size: rem(16);
  color: $c_link;
  text-align: center;
}

.footer {
  margin: rem(24) 0;
  font-size: rem(12);
  color: $c_note;
}
