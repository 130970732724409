@use '../_variable' as *;

.globalNavigation {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: rem(12);
  background: $c_bg;
}

.list {
  display: flex;
  padding: 0;
  margin: 0;
}

.item {
  flex-grow: 1;
  width: 50%;
  list-style: none;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: rem(60);
  padding: rem(6);
  font-size: rem(10);
  font-weight: bold;
  color: $c_dark;
  text-decoration: none;

  &[aria-current='page'] {
    color: $c_current;
    pointer-events: none;
  }
}

.icon {
  @include rect(rem(30));

  display: block;

  path {
    fill: currentColor;
  }
}
