@use '../_variable' as *;

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(36);

  &:not(.isOpen) {
    pointer-events: none;
    user-select: none;
  }

  &.isOpen {
    pointer-events: default;
    user-select: auto;
  }
}

.screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $c_screen;

  :not(.isOpen) & {
    opacity: 0;
    transition: opacity $t_ease;
  }

  .isOpen & {
    opacity: 1;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 86vh;
  overflow: hidden;
  color: $c_darkness;
  background-color: $c_lightness;
  border-radius: rem(12);

  :not(.isOpen) & {
    opacity: 0;
  }

  .isOpen & {
    opacity: 1;
    transition: opacity $t_ease;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 98%;
    padding: rem(24) rem(24) 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .title {
    margin-bottom: rem(12);
    font-size: rem(18);
    font-weight: bold;
    text-align: center;
  }

  .description {
    width: 100%;
    line-height: 1.8;
  }

  .button {
    @include tmp_button;

    max-width: calc(100% - #{rem(24 + 24)});
    margin: rem(24) 0;
  }
}

.confirmFooter {
  display: flex;
  gap: rem(6);
  justify-content: flex-end;
  width: 100%;
}

.buttonCancel,
.buttonAccept {
  min-width: 6em;
  padding: rem(16) 0;
  font-weight: 700;
  color: $c_link;
  background-color: $c_lightness;
  border: none;
  appearance: none;
}
