@use '../_variable' as *;

$time: 0.6s;

@mixin StepNav_Done {
  &::before {
    box-shadow: inset 0 0 0 rem(12) $c_current;
  }
  svg {
    z-index: 2;
    opacity: 1;
  }
  .icon {
    stroke-dasharray: 29.7833245;
    stroke-dashoffset: 29.7833245;
    animation: checkPath 240ms linear 0s forwards;
  }
}

.flow {
  box-sizing: border-box;
  min-height: rem(24 + 12 + 6);
  margin: rem(36) 0 0;
  overflow: hidden;
  text-align: center;
  transition: margin-top $time $t_duration, opacity $time $t_duration, min-height $time $t_duration,
    height $time $t_duration, padding $time $t_duration;

  [data-step='5'] & {
    // [data-step='6'] & {
    height: 0;
    min-height: 0;
    margin: rem(12) 0 0;
    opacity: 0;
  }
}

.stepList {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  width: calc(100% - #{rem(20 + 20)});
  padding: 0;
  margin: auto;
  list-style-type: none;
  counter-reset: step;

  &::before,
  &::after {
    position: absolute;
    top: rem(24 * 0.5);
    left: 0;
    display: block;
    height: 3px;
    // padding: 0 1em;
    margin: auto;
    white-space: nowrap;
    content: '';
    border-top: 3px solid;
  }

  &::before {
    width: 100%;
    border-color: $c_border;
    transition: border-top-width $time $t_duration, top $time $t_duration;

    [data-step='5'] & {
      // [data-step='6'] & {
      top: 0;
      border-top-width: 1px;
    }
  }

  &::after {
    border-color: $c_current;
    transition: width $t_duration, border-top-width $time $t_duration, top $time $t_duration;

    [data-step='1'] & {
      width: 0;
    }

    [data-step='2'] & {
      width: 33.3%;
      // width: 25%;
    }

    [data-step='3'] & {
      width: 66.6%;
      // width: 50%;
    }

    [data-step='4'] & {
      width: 100%;
    }

    [data-step='5'] & {
      top: 0;
      width: 100%;
      border-top-width: 1px;
    }

    // [data-step='5'] & {
    //   width: 100%;
    // }
    // [data-step='6'] & {
    //   top: 0;
    //   width: 100%;
    //   border-top-width: 1px;
    // }
  }
}

.step {
  position: relative;
  z-index: 2;
  display: block;
  width: rem(24);
  min-height: rem(24);
  margin: 0 auto;
  counter-increment: step;
  border-radius: 999rem;
  transition: background-color $t_duration $t_duration, min-height $time $t_duration,
    height $time $t_duration, width $time $t_duration;

  [data-step='5'] &,
  [data-step='5'] &::before {
    // [data-step='6'] &,
    // [data-step='6'] &::before {
    width: 0;
    height: 0;
    min-height: 0;
  }

  // step 2
  [data-step='2'] &:nth-child(-n + 1) {
    @include StepNav_Done;
  }

  // step 3
  [data-step='3'] &:nth-child(-n + 2) {
    @include StepNav_Done;
  }

  // step 4
  [data-step='4'] &:nth-child(-n + 3) {
    @include StepNav_Done;
  }

  // step 5
  [data-step='5'] &:nth-child(-n + 4) {
    @include StepNav_Done;
  }

  // step 6
  [data-step='6'] &:nth-child(-n + 5) {
    @include StepNav_Done;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(24);
    height: rem(24);
    margin: auto;
    font-size: rem(14);
    font-weight: bold;
    line-height: 1.5;
    color: $c_lightness;
    content: counter(step);
    background: $c_border;
    border-radius: 999rem;
    box-shadow: inset 0 0 0 rem(3) $c_border;
    transition: color $t_duration $t_duration, background-color $t_duration $t_duration,
      box-shadow $t_duration $t_duration, min-height $time $t_duration, height $time $t_duration,
      width $time $t_duration;
  }

  &:first-child {
    margin: 0 auto 0 0;
  }

  &:last-child {
    margin: 0 0 0 auto;
  }
}

.stepLabel {
  display: block;
  margin: 0 -2em;
  font-size: rem(10);
  line-height: 1.333;
  color: $c_note;
  text-align: center;
  white-space: nowrap;
  transition: color $t_duration $t_duration;

  // step 5
  [data-step='5'] & {
    opacity: 0;
  }

  // // step 6
  // [data-step='6'] & {
  //   opacity: 0;
  // }
}

.step svg {
  position: relative;
  z-index: 1;
  max-width: rem(24);
  max-height: rem(24);
  padding: rem(6);
  color: $c_lightness;
  background: $c_current;
  border-radius: 50%;
  opacity: 0;
  transition: height $time $t_duration, width $time $t_duration;

  [data-step='5'] & {
    display: none;
  }

  // [data-step='6'] & {
  //   display: none;
  // }
}
.icon {
  stroke: $c_lightness;
  stroke-width: 4;
}

//
// set status
.step[aria-current='page'] {
  &::before {
    color: $c_current;
    background-color: $c_lightness;
    box-shadow: inset 0 0 0 rem(3) $c_current;
  }
  .stepLabel {
    color: $c_dark;
  }
}
