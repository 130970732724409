@use '../_variable' as *;

.confirm {
  width: 100%;
}

.editGuide {
  display: inline-flex;
  align-items: center;
  padding-left: rem(18);
  font-size: rem(14);
  line-height: 1.5;
  color: $c_link;
  white-space: nowrap;

  svg {
    @include rect(rem(18));

    margin-right: rem(2);
    path {
      fill: $c_link;
    }
  }
}

.dl {
  width: 100%;
  padding: rem(18) 0 rem(16);
  font-size: rem(14);
  border-bottom: 1px solid $c_border;

  &:first-of-type:last-of-type {
    border: none;
  }
}
.dt {
  font-weight: bold;
}
.dd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: rem(12) 0 0 0;
  font-size: rem(16);
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: currentColor;
  text-decoration: none;
}

// ニックネーム空欄
.empty {
  font-size: rem(14);
  color: $c_note;
}
