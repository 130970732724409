@use '../_variable' as *;

.header {
  padding: 0 rem(36);
}

.pageTitle {
  @include tmp_headline_page;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 75vmin;
  padding: 0 rem(24);
}

.submit {
  @include tmp_button;

  &:disabled {
    background-color: $c_disabled;
  }
}

.note {
  margin: rem(32) 0 0;
  font-size: rem(14);
  text-align: center;
}
