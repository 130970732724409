@use '../function/rem' as *;
@use '../mixin/tracking' as *;
@use '../color' as *;


/** ボタン
* font-size 18 + line-height 1.5
*/
@mixin tmp_button($font-size: rem(16), $line-height: 1.5, $tracking: null) {
  @if $tracking != null {
    @include tracking($tracking);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: rem(54);
  padding: rem(6);
  margin: rem(24) 0;
  font-size: $font-size;
  font-weight: bold;
  line-height: $line-height;
  color: $c_lightness;
  background-color: $c_accent;
  border-radius: rem(4);
}
