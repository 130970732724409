@use '../_variable' as *;

// 一覧
.list {
  padding: 0;
  margin: 0;
  list-style: none;
  border-top: 1px solid $c_border;
}

.item {
  position: relative;
  padding: 0;
  margin: 0;
  font-size: rem(14);
  list-style: none;
  border-bottom: 1px solid $c_border;
}

// 見出し
.headline {
  margin: rem(36) 0 0;
  font-size: rem(16);
}

// ニックネーム
.nickname {
  margin: rem(18) 0;
  font-size: rem(14);
}
.nickname__dataList {
  margin: rem(18) 0;
  font-size: rem(14);
}
.nickname__title {
  font-weight: bold;
}
.nickname__data {
  margin: rem(12) 0 0 0;
  font-size: rem(16);
}
.nickname__edit {
  color: $c_link;
}

.editIcon {
  @include rect(rem(24));

  margin: auto 0 auto auto;
  path {
    fill: $c_link;
  }
}
.nickname__viewer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: currentColor;
  text-decoration: none;
}
