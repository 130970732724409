@use '../_variable' as *;

.termsOfUse {
  margin: auto 0 0;
  main ~ &,
  main ~ * & {
    padding: rem(24) rem(24) rem(36);
  }
}

.note {
  margin: rem(9) 0 0;
  font-size: rem(12);
  color: $c_note;
}

.privacy {
  display: block;
  color: $c_link;
  text-decoration: none;
}
